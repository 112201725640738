import { Helmet } from 'react-helmet-async'
import { Container, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import React from 'react'

import Logo from '../../vendor/OLI_Community_Logo.png'
import SignInComponent from '../../components/auth/SignIn'
import styled from 'styled-components/macro'

const PageContainer = styled.div`
  height: calc(100vh - 100px);
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

function SignIn() {
  const { t } = useTranslation()
  return (
    <PageContainer>
      <Helmet title="Login" />
      <img src={Logo} alt="logo" width={250} height={63} />
      <Container maxWidth="xs">
        <Typography
          component="h1"
          variant="h3"
          align="center"
          sx={{ mt: 8, mb: 6 }}
        >
          {t('LBLLogin')}
        </Typography>
        <SignInComponent />
      </Container>
    </PageContainer>
  )
}

export default SignIn

import { format } from 'date-fns'

export function formatDate(s) {
  const date = new Date()
  if (s && typeof s === 'string') {
    const data = s.split('-')
    if (data?.[0]) {
      date.setFullYear(parseInt(data[0]))
    }
    if (data?.[1]) {
      date.setMonth(parseInt(data[1]) - 1)
    }
    if (data?.[2]) {
      date.setDate(parseInt(data[2]))
    }
    return format(date, 'dd.MM.yyyy')
  }
}

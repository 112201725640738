import * as React from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import TablePagination from '@mui/material/TablePagination'
import {
  Typography,
  Button,
  Grid,
  Select,
  MenuItem,
  TextField,
  IconButton,
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import SearchIcon from '@mui/icons-material/Search'
import styled from 'styled-components/macro'
import { useState } from 'react'
import { useTheme } from '@mui/material/styles'
import biomassImg from './Assets/biomasse_oli.svg'
import gridImg from './Assets/netzstrom_oli.svg'
import solarImg from './Assets/solar_oli.svg'
import wasserImg from './Assets/wasserkraft_oli.svg'
import windImg from './Assets/wind_oli.svg'
import downloadImg from './Assets/downloadIcon.svg'
import { formatDecimal } from '../../services/utils/format-numbers'

//for the pagination component styling
const CustomTablePagination = styled(TablePagination)`
  && {
    color: ${(props) => props.theme.tokens.secondary};
    background-color: ${(props) => props.theme.tokens.surfaceBright};
  }
  .MuiPaginationItem-root,
  .MuiTablePagination-actions {
    color: ${(props) => props.theme.tokens.primary};
  }
`

const StyledDiv = styled.div`
  background-color: ${(props) => props.theme.tokens.tertiary};
  padding: 15px;
  width: 100%;
`
const StyledSelect = styled(Select)`
  && {
    background-color: ${(props) => props.theme.tokens.surfaceBright};
    width: 100%;
  }
`

const StyledLabel = styled(Typography)`
  && {
    color: ${(props) => props.theme.tokens.secondary};
    font-weight: bold;
    padding: 10px 5px;
  }
`
const StyledText = styled(Typography)`
  && {
    color: ${(props) => props.theme.tokens.primary};
  }
`
const SubColumnHeading = styled(Typography)`
  && {
    color: ${(props) => props.theme.tokens.secondary};
  }
`

//Search bar
const StyledTextField = styled(TextField)`
  && {
    border-radius: 40px;
    background-color: ${(props) => props.theme.tokens.surfaceBright};
    height: 50%;
    width: 35%;
    align-items: center;
    display: relative;
    top: 15px;
    input::placeholder {
      color: ${(props) => props.theme.tokens.secondary};
      font-weight: bold;
    }
    input {
      color: ${(props) => props.theme.tokens.secondary};
    }
  }
`
const StyledSelectContainer = styled.div`
  display: relative;
  align-items: center;
`
/**
 *
 * @param {contract} param0 - This component gets the details of a contract to show the data in the table
 * @returns {React.ReactNode} - It returns a table with the details of Energy consumed or produced by a particulat contract and the energy types.
 */

const ActiveUsersTable = ({ contract }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(5)
  const [searchText, setSearchText] = useState('')
  const [contractTypeFilter, setContractTypeFilter] = useState('')
  const [sortingOrder, setSortingOrder] = useState('asc')

  //maping of color to statuses
  const theme = useTheme()
  // Functions for handling filter changes
  const handleContractTypeFilterChange = (event) => {
    setContractTypeFilter(event.target.value)
  }

  //Filtering the rows of contractDataDto
  const filterContracts = (contract) => {
    return contract?.filter((contract) => {
      return (
        !contractTypeFilter || contract?.contractType === contractTypeFilter
      )
    })
  }

  // filtering of the data
  const filteredData =
    contract.filter((user) => {
      const filteredContracts =
        user.contractAndEnergyDetailsList?.filter(
          (contract) =>
            !contractTypeFilter || contract?.contractType === contractTypeFilter
        ) ?? []
      return (
        (user.fullName?.toLowerCase().includes(searchText.toLowerCase()) ??
          false) &&
        filteredContracts.length > 0
      )
    }) || []

  // Sorting of the data
  const sortedData = filteredData.map((user) => ({
    ...user,
    contractAndEnergyDetailsList: [
      ...(user.contractAndEnergyDetailsList ?? []),
    ].sort((a, b) =>
      a.contractType === 'PRODUCER'
        ? sortingOrder === 'asc'
          ? (a.totalProduction ?? 0) - (b.totalProduction ?? 0)
          : (b.totalProduction ?? 0) - (a.totalProduction ?? 0)
        : sortingOrder === 'asc'
        ? (a.totalConcumption ?? 0) - (b.totalConcumption ?? 0)
        : (b.totalConcumption ?? 0) - (a.totalConcumption ?? 0)
    ),
  }))

  const handleSortChange = (event) => {
    setSortingOrder(event.target.value)
  }

  // Change page function
  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const handleResetFilters = () => {
    setSearchText('')
    setContractTypeFilter('')
    setSortingOrder('')
  }

  return (
    <>
      {/* Search box and filter menus */}

      <StyledDiv>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 4 }}>
          <Grid item xs={4} md={6}>
            <StyledTextField
              placeholder={t('LBLSearch')}
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
              variant="standard"
              margin="normal"
              InputProps={{
                disableUnderline: true,
                endAdornment: (
                  <IconButton size="large" edge="end">
                    <SearchIcon sx={{ color: theme.tokens.primary }} />
                  </IconButton>
                ),
              }}
            />
          </Grid>

          {/* Total Energy Sorting type filter */}
          <Grid item xs={2} md={2}>
            <StyledSelectContainer>
              <StyledLabel variant="h6">{t('Sorting')}</StyledLabel>
              <StyledSelect
                value={sortingOrder}
                onChange={handleSortChange}
                displayEmpty
              >
                <MenuItem value="asc">{t('LBLTotalEnergyasc')}</MenuItem>
                <MenuItem value="desc">{t('LBLTotalEnergydesc')}</MenuItem>
              </StyledSelect>
            </StyledSelectContainer>
          </Grid>

          <Grid item xs={2} md={2}>
            {/* Contract type filter */}
            <StyledSelectContainer>
              <StyledLabel variant="h6">{t('LBLContractType')}</StyledLabel>
              <StyledSelect
                value={contractTypeFilter}
                onChange={handleContractTypeFilterChange}
                displayEmpty
                variant="outlined"
              >
                <MenuItem value="">{t('LBLAll')}</MenuItem>
                <MenuItem value="PRODUCER">{t('LBLProducer')}</MenuItem>
                <MenuItem value="CONSUMER">{t('LBLConsumer')}</MenuItem>
              </StyledSelect>
            </StyledSelectContainer>
          </Grid>
          <Grid item xs={2} md={2} sx={{ padding: '5px', marginTop: '40px' }}>
            <Button onClick={handleResetFilters}>
              <StyledText variant="h5" align="left">
                {t('LBLResetFilters')}
              </StyledText>
            </Button>
          </Grid>
        </Grid>
      </StyledDiv>
      <TableContainer component={Paper}>
        <Table sx={{ border: 'none' }} size="medium">
          <TableHead
            sx={{
              borderTop: '1px solid ',
              borderColor: (theme) => theme.tokens.outlineVariant,
              backgroundColor: (theme) => theme.tokens.surfaceContainerLow,
            }}
          >
            <TableRow
              sx={{
                borderBottom: 2.5,
                borderColor: (theme) => theme.tokens.secondary,
              }}
            >
              <TableCell
                sx={{
                  borderRight: '1px solid',
                  borderColor: (theme) => theme.tokens.secondary,
                }}
              >
                <SubColumnHeading variant="h5">
                  {t('LBLUserName')}
                </SubColumnHeading>
              </TableCell>

              <TableCell>
                <SubColumnHeading variant="h5">
                  {t('LBLContractsNumber')}
                </SubColumnHeading>
              </TableCell>
              <TableCell>
                <SubColumnHeading variant="h5">
                  {t('LBLLocation')}
                </SubColumnHeading>
              </TableCell>
              <TableCell>
                <SubColumnHeading variant="h5">
                  {t('LBLContractType')}
                </SubColumnHeading>
              </TableCell>
              <TableCell>
                <SubColumnHeading variant="h5">
                  {t('LBLTotalEnergy')}
                </SubColumnHeading>
              </TableCell>
              <TableCell>
                <img src={gridImg} alt="GridEnergy" />
              </TableCell>
              <TableCell>
                <img src={biomassImg} alt="Biomass" />
              </TableCell>
              <TableCell>
                <img src={solarImg} alt="SolarEnergy" />
              </TableCell>
              <TableCell>
                <img src={wasserImg} alt="HydroPower" />
              </TableCell>
              <TableCell>
                <img src={windImg} alt="WindEnergy" />
              </TableCell>
              <TableCell>
                <SubColumnHeading variant="h5"></SubColumnHeading>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody style={{ backgroundColor: theme.tokens.surfaceBright }}>
            {sortedData
              .slice(
                page * rowsPerPage,
                rowsPerPage > 0
                  ? page * rowsPerPage + rowsPerPage
                  : filteredData.length
              )
              .map((user, userIndex) => (
                <React.Fragment key={userIndex}>
                  {user.contractAndEnergyDetailsList &&
                    filterContracts(user.contractAndEnergyDetailsList).map(
                      (contract, contractIndex) => (
                        <TableRow
                          key={contractIndex}
                          style={{
                            borderBottom: `${
                              contractIndex ===
                              user.contractAndEnergyDetailsList.length - 1
                                ? 2.5
                                : 1
                            }px solid`,
                            color: (theme) => theme.tokens.secondary,
                          }}
                        >
                          {contractIndex === 0 && (
                            <TableCell
                              rowSpan={user.contractAndEnergyDetailsList.length}
                              style={{
                                borderRight: 'thin solid',
                                color: (theme) => theme.tokens.secondary,
                              }}
                            >
                              <Button
                                sx={{ fontWeight: 'bold' }}
                                onClick={() =>
                                  navigate(`/nutzer/${user.fullName}/`, {
                                    state: { user },
                                  })
                                }
                              >
                                <StyledText variant="h5">
                                  {user.fullName || '--'}
                                </StyledText>
                              </Button>
                            </TableCell>
                          )}
                          <TableCell>
                            {contract.contractNumber || '--'}
                          </TableCell>
                          <TableCell>{contract.city || '--'}</TableCell>
                          <TableCell>
                            {contract.contractType === 'PRODUCER'
                              ? t('LBLProducer')
                              : t('LBLConsumer')}
                          </TableCell>
                          <TableCell>
                            {contract.contractType === 'PRODUCER'
                              ? formatDecimal(contract.totalProduction)
                              : formatDecimal(contract.totalConcumption)}
                          </TableCell>
                          <TableCell>
                            {contract.gridEnergy !== null
                              ? formatDecimal(contract.gridEnergy)
                              : '--'}
                          </TableCell>
                          <TableCell>
                            {contract.biomasEnergy !== null
                              ? formatDecimal(contract.biomasEnergy)
                              : '--'}
                          </TableCell>
                          <TableCell>
                            {contract.solarEnergy !== null
                              ? formatDecimal(contract.solarEnergy)
                              : '--'}
                          </TableCell>
                          <TableCell>
                            {contract.hydroEnergy !== null
                              ? formatDecimal(contract.hydroEnergy)
                              : '--'}
                          </TableCell>
                          <TableCell>
                            {contract.windEnergy !== null
                              ? formatDecimal(contract.windEnergy)
                              : '--'}
                          </TableCell>
                          <TableCell>
                            {/* This is the download button for downloading the details of a contract in excel format. Its API is still under development. */}
                            <Button>
                              <img src={downloadImg} alt="DownloadIcon" />
                            </Button>
                          </TableCell>
                        </TableRow>
                      )
                    )}
                </React.Fragment>
              ))}
          </TableBody>
        </Table>
        <CustomTablePagination
          rowsPerPageOptions={[5, 10, 25, { label: 'Alle', value: -1 }]}
          component="div"
          count={filteredData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage={t('LBLRowsPerPage')}
          showLastButton
          showFirstButton
          labelDisplayedRows={({ from, to, count }) =>
            t('Pagination', { from, to, count })
          }
        />
      </TableContainer>
    </>
  )
}

export default ActiveUsersTable

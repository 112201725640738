import {
  CardContent,
  Typography,
  Select,
  IconButton,
  Tooltip,
  MenuItem,
} from '@mui/material'
import { View, Spacer, Item } from './styles'
import { useTranslation } from 'react-i18next'
import { useCallback, useState, useEffect } from 'react'
import CheckIcon from '@mui/icons-material/Check'
import CloseIcon from '@mui/icons-material/Close'
import Feedback from '../../../components/Feedback'
import FeedbackError from '../../../components/DataTable/Feedback'
import { useAuth } from '../../../services/contexts'
import api from '../../../Middleware/api/api'
import { styled } from '@mui/material/styles'
import React from 'react'

const StyledView = styled(View)`
  && {
    border-radius: 28px;
    overflow: hidden;
    background-color: ${(props) => props.theme.tokens.surfaceBright};
  }
`
const StyledTypography = styled(Typography)`
  && {
    color: ${(props) => props.theme.tokens.secondary};
  }
`

function OtherData({ contract }) {
  const { token } = useAuth()
  const { t } = useTranslation()
  const [status, setStatus] = useState(contract?.contractStatus) // Set initial status here
  const [open, setOpen] = useState(false)
  const [tempStatus, setTempStatus] = useState(status)
  const [showIcons, setShowIcons] = useState(false)
  const [successFeedback, setSuccessFeedback] = useState(false)
  const [errorFeedback, setErrorFeedback] = useState(false)
  const [showContractStatusError, setShowContractStatusError] = useState(false)

  useEffect(() => {
    if (contract?.contractStatus) {
      setStatus(contract.contractStatus)
    }
  }, [contract?.contractStatus])

  const handleStatusChange = (event) => {
    setTempStatus(event.target.value)
    setStatus(event.target.value)
    setShowIcons(true)
  }

  const handleClose = () => {
    // Close the dropdown without updating the status
    setShowIcons(false)
    setTempStatus(status) // Reset tempStatus to current status value
    setStatus(contract?.contractStatus)
  }

  const contractDetails = {
    ContractType: contract?.contractType
      ? contract.contractType === 'PRODUCER'
        ? t('LBLProducer')
        : t('LBLConsumer')
      : '',
    ContractStatus: contract?.contractStatus,
    TaxDetails: contract?.salesTaxLiability
      ? contract.salesTaxLiability === 'No'
        ? t('UserDetailNotSelected')
        : t('UserDetailSelected')
      : '',
    agb: contract?.agb,
    MarketingInformation: contract?.marketingInformation,
  }

  //API Call for updating contract status
  const handleUpdateStatus = async () => {
    if (!contract?.contractNumber && status === 'ACTIVE')
      return setShowContractStatusError(true)
    const contractKey = contract.contractKey
    try {
      // Call the API method
      const response = await api.updateContractStatus(
        token,
        contractKey,
        tempStatus
      )
      setSuccessFeedback(response.data)
      setStatus(tempStatus)
      setShowIcons(false)
    } catch (error) {
      setErrorFeedback(true)
    }
  }

  const renderKey = useCallback(
    (key) => {
      switch (key) {
        case 'ContractType':
          return t('LBLContractType')
        case 'ContractStatus':
          return t('LBLUserStatus')
        case 'TaxDetails':
          return t('LBLSalesTaxLiability')
        case 'agb':
          return t('LBLAgb')
        case 'MarketingInformation':
          return t('UserDetailsMarketingInformation')
        default:
          return key
      }
    },
    [t]
  )

  return (
    <>
      <StyledView>
        <CardContent>
          <StyledTypography fontWeight="bold" variant="h4" gutterBottom>
            {t('LBLOtherData')}
          </StyledTypography>
          <Spacer mb={4} />
          <StyledTypography variant="body2" component="div" gutterBottom>
            {contractDetails && (
              <>
                {Object.entries(contractDetails).map(([key, value]) => {
                  return (
                    <>
                      <Item key={key}>
                        <StyledTypography fontWeight="fontWeightMedium">
                          {renderKey(key)}
                        </StyledTypography>
                        {key === 'ContractStatus' ? (
                          <>
                            <div
                              style={{
                                position: 'relative',
                                display: 'inline-block',
                              }}
                            >
                              <Select
                                id="contractStatus"
                                name="contractStatus"
                                value={status}
                                onChange={handleStatusChange}
                                open={open}
                                onClose={() => setOpen(false)}
                                onOpen={() => setOpen(true)}
                                // onOpen={handleOpen}
                                onClick={() => setShowIcons(true)}
                                MenuProps={{
                                  PaperProps: {
                                    style: {
                                      transform: 'translateY(10px)', // Adjust the position of the dropdown
                                    },
                                  },
                                  getContentAnchorEl: null,
                                  anchorOrigin: {
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                  },
                                }}
                              >
                                <MenuItem value="ACTIVE">
                                  {t('LBLActive')}
                                </MenuItem>
                                <MenuItem value="INACTIVE">
                                  {t('LBLExpired')}
                                </MenuItem>
                                <MenuItem value="IN_PROGRESS">
                                  {t('LBLInreview')}
                                </MenuItem>
                              </Select>

                              {showIcons && (
                                <div
                                  style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    marginLeft: '8px',
                                  }}
                                >
                                  <Tooltip title="Confirm">
                                    <IconButton onClick={handleUpdateStatus}>
                                      <CheckIcon />
                                    </IconButton>
                                  </Tooltip>
                                  <Tooltip title="Cancel">
                                    <IconButton onClick={handleClose}>
                                      <CloseIcon />
                                    </IconButton>
                                  </Tooltip>
                                </div>
                              )}
                            </div>
                          </>
                        ) : (
                          <StyledTypography
                            fontWeight="fontWeightRegular"
                            align="right"
                          >
                            {typeof value === 'boolean'
                              ? value
                                ? t('UserDetailSelected')
                                : t('UserDetailNotSelected')
                              : ['string', 'number'].includes(typeof value)
                              ? value
                              : Array.isArray(value)
                              ? value?.join(', ')
                              : null}
                          </StyledTypography>
                        )}
                      </Item>
                      <div style={{ height: '5px' }}></div>
                    </>
                  )
                })}
              </>
            )}
          </StyledTypography>
        </CardContent>
      </StyledView>
      <Feedback
        open={successFeedback}
        title={t('LBLStatusUpdatedTitle')}
        message={
          <>
            <StyledTypography align="center">
              {t('LBLStatusUpdated')}.
            </StyledTypography>
          </>
        }
        handleClose={() => setSuccessFeedback(false)}
      />
      <FeedbackError
        open={errorFeedback}
        title={t('LBLFailure')}
        message={
          <>
            <StyledTypography align="center">
              {t('LBLSomethingwentWrong')}
            </StyledTypography>
          </>
        }
        severity="warning"
        handleClose={() => {
          setErrorFeedback(false)
        }}
      />
      {/* Error message if contract Number is empty/null */}
      <FeedbackError
        open={showContractStatusError}
        title={t('LBLWarning')}
        message={
          <>
            <StyledTypography align="center">
              {t('LBLDisabledMessage')}
            </StyledTypography>
          </>
        }
        severity="warning"
        handleClose={() => {
          setShowContractStatusError(false)
          setShowIcons(false)
        }}
      />
    </>
  )
}

export default OtherData

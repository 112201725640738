import styled from 'styled-components/macro'
import Table from '@mui/material/Table'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import TablePagination from '@mui/material/TablePagination'
import { Typography } from '@mui/material'

export const CustomTablePagination = styled(TablePagination)`
  && {
    color: ${(props) => props.theme.tokens.secondary};
    background-color: ${(props) => props.theme.tokens.surfaceBright};
  }
  .MuiPaginationItem-root,
  .MuiTablePagination-actions {
    color: ${(props) => props.theme.tokens.primary};
  }
`

export const StyledTableColumnRow = styled(TableCell)`
  && {
    color: ${(props) => props.theme.tokens.secondary};
    background-color: ${(props) => props.theme.tokens.surfaceBright};
    text-align: left;
    display: relative;
    justify-content: start;
    align-items: left;
    width: 15vw;
  }
`

export const StyledUserNameAndMainColumn = styled(TableCell)`
  && {
    background-color: ${(props) => props.theme.tokens.surfaceBright};
    border: none;
  }
`
export const StyledTableCell = styled(TableCell)`
  && {
    color: ${(props) => props.theme.tokens.secondary};
    background-color: ${(props) => props.theme.tokens.surfaceBright};
    text-align: left;
    display: relative;
    justify-content: start; /* Change this value to flex-start, center, flex-end, or space-between as needed */
    align-items: left;
    width: 15vw;
  }
`

export const SubColumnHeading = styled(Typography)`
  && {
    color: ${(props) => props.theme.tokens.secondary};
  }
`

export const StyledTableRow = styled(TableRow)`
  && {
    border: none; /* Remove border */
  }
`

export const StyledTable = styled(Table)`
  border: none;
`

export const StyledText = styled(Typography)`
  && {
    color: ${(props) => props.theme.tokens.primary};
    width: 100%;
    text-align: center;
  }
`

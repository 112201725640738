import { useState } from 'react'
import React from 'react'
import { ArrowForward } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'
import EditUserDetails from './EditUserDetails'
import StepperPopup from '../../../../components/StepperPopup'
import ConfirmUserDetails from './ConfirmUserDetails'
import PaymentDetails from './PaymentDetails'
import ProductionPlantDetails from './ProductionPlantDetails'
import OtherDataDetails from './OtherDataDetails'
import BillingAddressDetails from './BillingAddressDetails'
import { useSelector } from 'react-redux'

/**
 * The main component that shows the user details after user clicks on the details in the table of Nutzerverwaltung page.
 * This also holds the button to edit the details of a contract.
 *
 * @returns {React.ReactNode} - Returns all the component that are show in user details page.
 */

export default function UserFormPopup({
  onLoadingChanged,
  isConsumer,
  onFormCompleted,
  onIsError,
  contract,
  onIsFormOpened,
  isEditingContract,
  refetch,
}) {
  const { t } = useTranslation()
  const [step, setStep] = useState(0)
  const [canNext, setCanNext] = useState(false)
  const [submit, setSubmit] = useState(0)
  const [confirmed, setConfirmed] = useState(0)
  const [canNextOne, setCanNextOne] = useState(false)
  const [canNextTwo, setCanNextTwo] = useState(false)
  const [canNextThree, setCanNextThree] = useState(false)
  const [canNextBilling, setCanNextBilling] = useState(false)
  const [submitOne, setSubmitOne] = useState(0)
  const [submitTwo, setSubmitTwo] = useState(0)
  const [submitThree, setSubmitThree] = useState(0)
  const [submitBilling, setSubmitBilling] = useState(0)
  const handleNext = () => setStep(step + 1)
  const handlePrev = () => setStep(step - 1)
  const [payload, setPayload] = useState()
  const [payloadOne, setPayloadOne] = useState()
  const [payloadTwo, setPayloadTwo] = useState()
  const [payloadThree, setPayloadThree] = useState()
  const [payloadBilling, setPayloadBilling] = useState()

  const isBillingAddressSame = useSelector(
    (state) => state.address.isBillingAddressSame
  )

  const handleSetPayload = (input) => {
    setPayload(input)
  }
  const handleSetPayloadOne = (input) => {
    setPayloadOne(input)
  }
  const handleSetPayloadTwo = (input) => {
    setPayloadTwo(input)
  }

  const handleSetPayloadThree = (input) => {
    setPayloadThree(input)
  }

  const handleSetPayloadBilling = (input) => {
    setPayloadBilling(input)
  }

  const setSubmittingConfirm = () => {
    onIsFormOpened(false)
  }
  const handleNextevent = (input) => {
    handleNext()
  }
  const onClose = (_event, reason) => {
    if (reason !== 'backdropClick') {
      setStep(0)
      setPayload(null)
    }
  }
  /** @type {import("@mui/material").DialogProps['onClose']} */
  const onCloseOne = (_event, reason) => {
    if (reason !== 'backdropClick') {
      setPayloadOne(null)

      setStep(1)
    }
  }
  /** @type {import("@mui/material").DialogProps['onClose']} */
  const onCloseTwo = (_event, reason) => {
    if (reason !== 'backdropClick') {
      setPayloadTwo(null)
      setStep(2)
    }
  }
  /** @type {import("@mui/material").DialogProps['onClose']} */
  const onCloseThree = (_event, reason) => {
    if (reason !== 'backdropClick') {
      setPayloadThree(null)
      setStep(3)
    }
  }

  return (
    <StepperPopup
      widthInput="1000"
      title={t('LBLEditUser')}
      isOpen={isEditingContract}
      setIsOpen={onIsFormOpened}
      skipBillingAddress={isBillingAddressSame}
      steps={[
        {
          label: t('LBLUserInfo'),
          component: (
            <EditUserDetails
              setPayload={handleSetPayload}
              handleClose={onClose}
              confirmPayload={handleNext}
              payload={contract}
              setSubmit={setSubmit}
              setCanNext={setCanNext}
              submit={submit}
            />
          ),
          nextAction: {
            label: t('LBLFurther'),
            icon: <ArrowForward />,
            action: () => {
              setSubmit((prevState) => prevState + 1)
            },
            canNext: canNext,
          },
        },
        {
          label: t('LBLBillingAddressInfo'),
          component: (
            <BillingAddressDetails
              setPayload={handleSetPayloadBilling}
              handleClose={onClose}
              confirmPayload={handleNext}
              payload={contract}
              setSubmit={setSubmitBilling}
              setCanNext={setCanNextBilling}
              submit={submitBilling}
            />
          ),
          nextAction: {
            label: t('LBLFurther'),
            icon: <ArrowForward />,
            action: () => {
              setSubmitBilling((prevState) => prevState + 1)
            },
            canNext: canNextBilling,
          },
        },
        {
          label: t('LBLAccountData'),
          component: (
            <PaymentDetails
              setPayloadOne={handleSetPayloadOne}
              handleClose={onCloseOne}
              confirmPayload={handleNextevent}
              payload={contract}
              setSubmit={setSubmitOne}
              setCanNext={setCanNextOne}
              submit={submitOne}
            />
          ),
          nextAction: {
            label: t('LBLFurther'),
            icon: <ArrowForward />,
            action: () => {
              setSubmitOne((prevState) => prevState + 1)
            },
            canNext: canNextOne,
          },
        },
        {
          label: isConsumer
            ? t('LBLDeliveryPoint')
            : t('LBLGenerationPlantDetails'),
          component: (
            <ProductionPlantDetails
              setPayload={handleSetPayloadTwo}
              handleClose={onCloseTwo}
              confirmPayload={handleNext}
              payload={contract}
              setSubmit={setSubmitTwo}
              setCanNext={setCanNextTwo}
              submit={submitTwo}
            />
          ),
          nextAction: {
            label: t('LBLFurther'),
            icon: <ArrowForward />,
            action: () => {
              setSubmitTwo((prevState) => prevState + 1)
            },
            canNext: canNextTwo,
          },
        },
        {
          label: t('LBLOtherData'),
          component: (
            <OtherDataDetails
              setPayloadThree={handleSetPayloadThree}
              handleClose={onCloseThree}
              confirmPayload={handleNext}
              payload={contract}
              setSubmit={setSubmitThree}
              setCanNext={setCanNextThree}
              submit={submitThree}
            />
          ),
          nextAction: {
            label: t('LBLFurther'),
            icon: <ArrowForward />,
            action: () => {
              setSubmitThree((prevState) => prevState + 1)
            },
            canNext: canNextThree,
          },
        },
        {
          label: t('confirmInfo'),
          component: (
            <ConfirmUserDetails
              payload={{
                salutation: payload?.salutation,
                fullName: payload?.fullName,
                address: payload?.address,
                billingAddress: isBillingAddressSame
                  ? {
                      addressOwner: payload?.fullName,
                      city: payload?.address?.city,
                      street: payload?.address?.street,
                      houseNr: payload?.address?.houseNr,
                      postalCode: payload?.address?.postalCode,
                    }
                  : {
                      addressOwner:
                        payloadBilling?.billingAddress?.addressOwner,
                      city: payloadBilling?.billingAddress?.city,
                      street: payloadBilling?.billingAddress?.street,
                      houseNr: payloadBilling?.billingAddress?.houseNr,
                      postalCode: payloadBilling?.billingAddress?.postalCode,
                    },
                registeredCourt: payload?.registeredCourt,
                registrationNumber: payload?.registrationNumber,
                phoneNumber: payload?.phoneNumber,
                dateOfBirth: payload?.dateOfBirth,
                emailAddress: payload?.emailAddress,
                contractNumber: payloadThree?.contractNumber,
                contractStatus: payloadThree?.contractStatus,
                contractType: payloadThree?.contractType,
                preference: payloadTwo?.preference,
                startOfDelivery: payloadTwo?.startOfDelivery,
                nextPossibleDate: payloadTwo?.nextPossibleDate,
                plantDetails: {
                  plantType: payloadTwo?.plantDetails?.plantType,
                  plantName: payloadTwo?.plantDetails?.plantName,
                  lastSupplier: payloadTwo?.plantDetails?.lastSupplier,
                  costumerNumber: payloadTwo?.plantDetails?.costumerNumber,
                  plantAddress: payloadTwo?.plantDetails?.plantAddress,
                  installedPowerKw: payloadTwo?.plantDetails?.installedPowerKw,
                  commisioningDate: payloadTwo?.plantDetails?.commisioningDate,
                  marketLocation: payloadTwo?.plantDetails?.marketLocation,
                  meterNumber: payloadTwo?.plantDetails?.meterNumber,
                  netOperator: payloadTwo?.plantDetails?.netOperator,
                  plantKey: payloadTwo?.plantDetails?.plantKey,
                },
                salesTaxLiability: payloadThree?.salesTaxLiability,
                paymentDetails: {
                  bankName: payloadOne?.bankName,
                  bic: payloadOne?.bic,
                  iban: payloadOne?.iban,
                  accountHolder: payloadOne?.accountHolder,
                  accept: payloadOne?.accept,
                },
                stadtWerkeKunden: payloadTwo?.stadtWerkeKunden,
                marketingInformation: payloadThree?.marketingInformation,
                agb: payloadThree?.agb,
                contractKey: contract?.contractKey,
                addressSame: payload?.addressSame,
                userId: contract?.userId,
              }}
              handleClose={onClose}
              openFeedback={() => onFormCompleted(true)}
              backToForm={handlePrev}
              setError={() => onIsError(true)}
              setSubmittingConfirm={setSubmittingConfirm}
              submit={confirmed}
              setSubmit={setConfirmed}
              contract={contract}
              refetch={refetch}
            />
          ),
          nextAction: {
            label: t('LBLSave'),
            action: () => {
              setConfirmed((prevState) => prevState + 1)
              onIsFormOpened(false)
              onLoadingChanged(false)
            },
          },
        },
      ]}
    />
  )
}

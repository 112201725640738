import styled from 'styled-components/macro'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useTheme } from 'styled-components'
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ReferenceLine,
  ResponsiveContainer,
} from 'recharts'
import { GraphContainer } from '../Dashbord/styles'
import { formatDecimal } from '../../services/utils/format-numbers'

const TooltipContainer = styled.div`
  background: ${(props) => props.theme.tokens.surfaceBright};
  border: 1px solid #ccc;
  padding: 5px;
  border-radius: 5px;
`

const Label = styled.p`
  color: ${(props) => props.color};
`

//months mapping to abbreviations
const monthAbbreviations = {
  JANUARY: 'Jan',
  FEBRUARY: 'Feb',
  MARCH: 'Mär',
  APRIL: 'Apr',
  MAY: 'Mai',
  JUNE: 'Jun',
  JULY: 'Jul',
  AUGUST: 'Aug',
  SEPTEMBER: 'Sep',
  OCTOBER: 'Okt',
  NOVEMBER: 'Nov',
  DECEMBER: 'Dez',
}

const dataWithZeroValues = [
  {
    time: 'January',
    bioMasse: 0.0,
    solar: 0.0,
    windStrom: 0.0,
    wasserKraft: 0.0,
    restStrom: 0.0,
  },
  {
    time: 'February',
    bioMasse: 0.0,
    solar: 0.0,
    windStrom: 0.0,
    wasserKraft: 0.0,
    restStrom: 0.0,
  },
  {
    time: 'March',
    bioMasse: 0.0,
    solar: 0.0,
    windStrom: 0.0,
    wasserKraft: 0.0,
    restStrom: 0.0,
  },
  {
    time: 'April',
    bioMasse: 0.0,
    solar: 0.0,
    windStrom: 0.0,
    wasserKraft: 0.0,
    restStrom: 0.0,
  },
  {
    time: 'May',
    bioMasse: 0.0,
    solar: 0.0,
    windStrom: 0.0,
    wasserKraft: 0.0,
    restStrom: 0.0,
  },
  {
    time: 'June',
    bioMasse: 0.0,
    solar: 0.0,
    windStrom: 0.0,
    wasserKraft: 0.0,
    restStrom: 0.0,
  },
  {
    time: 'July',
    bioMasse: 0.0,
    solar: 0.0,
    windStrom: 0.0,
    wasserKraft: 0.0,
    restStrom: 0.0,
  },
  {
    time: 'August',
    bioMasse: 0.0,
    solar: 0.0,
    windStrom: 0.0,
    wasserKraft: 0.0,
    restStrom: 0.0,
  },
  {
    time: 'September',
    bioMasse: 0.0,
    solar: 0.0,
    windStrom: 0.0,
    wasserKraft: 0.0,
    restStrom: 0.0,
  },
  {
    time: 'October',
    bioMasse: 0.0,
    solar: 0.0,
    windStrom: 0.0,
    wasserKraft: 0.0,
    restStrom: 0.0,
  },
  {
    time: 'November',
    bioMasse: 0.0,
    solar: 0.0,
    windStrom: 0.0,
    wasserKraft: 0.0,
    restStrom: 0.0,
  },
  {
    time: 'December',
    bioMasse: 0.0,
    solar: 0.0,
    windStrom: 0.0,
    wasserKraft: 0.0,
    restStrom: 0.0,
  },
]

const mergeData = (template, data) => {
  const dataMap = data.reduce((map, item) => {
    map[item.time.toLowerCase()] = item
    return map
  }, {})

  return template.map((item) => {
    const key = item.time.toLowerCase()
    return dataMap[key] ? { ...item, ...dataMap[key] } : item
  })
}

const PriceBarGraph = ({ priceData, selectedType }) => {
  const { t } = useTranslation()
  const theme = useTheme()

  const data = mergeData(dataWithZeroValues, priceData || [])
  const abbreviateMonth = (month) => {
    return monthAbbreviations[month.toUpperCase()] || month
  }
  // custom tool tip for bars
  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      // Replace the label with the corresponding German month name
      const translatedLabel = monthAbbreviations[label.toUpperCase()] || label

      // This function is use to access the value and check the null.
      const getValue = (index) =>
        payload[index] && payload[index].value != null
          ? formatDecimal(payload[index].value)
          : null

      return (
        <TooltipContainer className="custom-tooltip">
          <Label
            className="label"
            color={theme.tokens.secondary}
          >{`${translatedLabel}`}</Label>
          {getValue(0) !== null && (
            <Label className="intro" color={theme.tokens.primary}>{`${t(
              'LBLBioMass'
            )}: ${getValue(0)}`}</Label>
          )}
          {getValue(1) !== null && (
            <Label
              className="intro"
              color={theme.tokens.customColorSignal}
            >{`${t('LBLSolarPower')}: ${getValue(1)}`}</Label>
          )}
          {getValue(2) !== null && (
            <Label
              className="intro"
              color={theme.tokens.secondaryFixedDim}
            >{`${t('LBLWindEnergy')}: ${getValue(2)}`}</Label>
          )}
          {getValue(3) !== null && (
            <Label className="intro" color={theme.tokens.secondary}>{`${t(
              'LBLHydroPower'
            )}: ${getValue(3)}`}</Label>
          )}
          {selectedType === 'CONSUMER' && getValue(4) !== null && (
            <Label className="intro" color={theme.tokens.outline}>{`${t(
              'LBLGrid'
            )}: ${getValue(4)}`}</Label>
          )}
        </TooltipContainer>
      )
    }
    return null
  }

  // Custom tick formatter for Y-axis
  const yAxisTickFormatter = (tick) => `${formatDecimal(tick)}`

  // Custom tick formatter for X-axis to abbreviate month names
  const xAxisTickFormatter = (tick) => abbreviateMonth(tick)
  return (
    <>
      <GraphContainer>
        <ResponsiveContainer width="97%" height="90%">
          <BarChart
            data={data}
            margin={{ top: 28, right: 40, left: 20, bottom: 5 }}
          >
            <CartesianGrid strokeDasharray="none" />
            <XAxis
              dataKey="time"
              tick={{ fill: theme.tokens.secondary }}
              label={{
                value: 'Monat',
                position: 'insideBottomRight', // Position at the end of X-axis
                dy: -12,
                dx: 40, // Adjust dy to position it better
                fill: theme.tokens.secondary,
                offset: 0,
              }}
              tickFormatter={xAxisTickFormatter} // Use custom tick formatter
            />
            <YAxis
              tickFormatter={yAxisTickFormatter}
              tick={{ fill: theme.tokens.secondary }}
              label={{
                value: 'Abschlag in ct €',
                // angle: -90,  // Rotate the label
                position: 'insideTopLeft', // Position at the end of Y-axis
                dy: -30,
                dx: 25, // Adjust dy to position it better
                fill: theme.tokens.secondary,
                offset: 5, // Adjust if needed to fine-tune position
              }}
            />
            <Tooltip content={<CustomTooltip />} />
            <ReferenceLine y={0} stroke="#000" />
            <Bar dataKey="bioMasse" fill={theme.tokens.primary} />
            <Bar dataKey="solar" fill={theme.tokens.customColorSignal} />
            <Bar dataKey="windStrom" fill={theme.tokens.secondaryFixedDim} />
            <Bar dataKey="wasserKraft" fill={theme.tokens.secondary} />
            {/* <Bar dataKey="Grid" fill={theme.tokens.outline} /> */}
            {selectedType === 'CONSUMER' && (
              <Bar dataKey="restStrom" fill={theme.tokens.outline} />
            )}
          </BarChart>
        </ResponsiveContainer>
      </GraphContainer>
    </>
  )
}

export default PriceBarGraph

import styled from 'styled-components/macro'
import {
  Button,
  Paper as MuiPaper,
  Box,
  Typography,
  TextField,
} from '@mui/material'

/**
 * This is the file to hold all the styles that are used in Abschlage screen.
 */

export const HeaderTypography = styled(Typography)`
  && {
    color: ${(props) => props.theme.tokens.onSurfaceVariant};
    margin-top: 125px;
  }
`
export const SubHeadingTypography = styled(Typography)`
  && {
    color: ${(props) => props.theme.tokens.secondary};
    position: flex;
  }
`
export const OuterSurface = styled(MuiPaper)`
  && {
    background-color: ${(props) => props.theme.tokens.surfaceBright};
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    padding: 50px 50px;
    max-width: 98%;
    display: flex;
    flex-direction: column;
    align-items: start;
  }
`

export const StyledDiv = styled(Box)(({ theme }) => ({
  backgroundColor: theme.tokens.tertiary,
  display: 'flex',
  flexWrap: 'wrap',
  padding: '16px 24px 28px 24px',
  alignItems: 'flex-end',
  gap: '53px',
  [theme.breakpoints.up('xs')]: {
    width: '98%', // Adjust top value for extra-small screens
  },
  [theme.breakpoints.up('sm')]: {
    width: '98%', // Adjust top value for small screens
  },
  [theme.breakpoints.up('md')]: {
    width: '98%', // Adjust top value for medium screens
  },
  [theme.breakpoints.up('lg')]: {
    width: '98%', // Adjust top value for large screens
  },
  [theme.breakpoints.up('xl')]: {
    width: '98%', // Adjust top value for extra-large screens
  },
}))

export const MessageBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  width: '100%',
  borderTop: `2px solid ${theme.tokens.secondary}`,
  borderBottom: `2px solid ${theme.tokens.secondary}`,
  borderLeft: 'none',
  borderRight: 'none',
  padding: theme.spacing(4),
}))

export const IconWrapper = styled(Box)`
  && {
    padding-right: 20px;
  }
`

export const MessageDiv = styled.div`
  flex: 1;
  max-width: 100%;
  white-space: wrap; // Prevent text from breaking into multiple lines
`
export const CustomTextField = styled(TextField)(({ theme }) => ({
  '& .MuiInputBase-root': {
    backgroundColor: theme.tokens.surfaceBright,
  },
  width: '100%',
  [theme.breakpoints.up('xs')]: {
    width: '90%',
  },
  [theme.breakpoints.up('sm')]: {
    width: '90%',
  },
  [theme.breakpoints.up('md')]: {
    width: '80%',
  },
  [theme.breakpoints.up('lg')]: {
    width: '80%',
  },
  [theme.breakpoints.up('xl')]: {
    width: '60%',
  },
}))

export const CustomizedButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.tokens.primary,
  boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
  position: 'relative',
  display: 'flex',
  padding: '7px 14px',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '8px',
  border: `1px solid ${theme.tokens.primary}`,
  color: theme.tokens.onSurfaceVariant,
  flex: '1 0 auto', // Adjust flex grow

  // Responsive width adjustments
  width: '100%',

  [theme.breakpoints.up('xs')]: {
    width: '80%',
  },
  [theme.breakpoints.up('sm')]: {
    width: '70%',
  },
  [theme.breakpoints.up('md')]: {
    width: '70%',
  },
  [theme.breakpoints.up('lg')]: {
    width: '90%',
  },
  [theme.breakpoints.up('xl')]: {
    width: '90%',
  },
}))

export const TableCellBox = styled(Box)`
  && {
    display: flex;
    align-items: start;
    justify-content: flex-start;
  }
`

//styling for buttons
export const ButtonGroup = styled(Box)`
  && {
    display: flex;
    padding: 24px 0px;
    position: relative;
    top: 0;
    left: 0;
    button {
      border-radius: 0;
    }
    button:first-of-type {
      border-top-left-radius: 20px;
      border-bottom-left-radius: 20px;
    }
    button:last-of-type {
      border-top-right-radius: 20px;
      border-bottom-right-radius: 20px;
    }
    @media (max-width: 600px) {
      flex-direction: column;
      align-items: center;
      button {
        min-width: 100%;
        margin-bottom: 8px;
      }
      button:last-of-type {
        margin-bottom: 0;
      }
    }

    @media (min-width: 601px) and (max-width: 900px) {
      flex-direction: row;
      flex-wrap: wrap;
      button {
        flex: 1 0 45%; /* Adjust width of buttons for medium screens */
        margin: 4px;
      }
    }

    @media (min-width: 901px) {
      flex-direction: row;
      button {
        flex: 1;
        margin: 0;
      }
    }
  }
`

export const StyledMenuButton = styled(Button)`
  && {
    &.MuiButton-containedPrimary {
      background-color: ${(props) => props.theme.tokens.tertiaryFixed};
      border: 1px solid ${(props) => props.theme.tokens.primary};
    }
    &.MuiButton-outlined {
      border: 1px solid ${(props) => props.theme.tokens.primary};
    }
    &.selected {
      background-color: ${(props) => props.theme.tokens.tertiaryFixed};
      color: ${(props) => props.theme.tokens.onTertiaryFixedVariant};
      svg {
        margin-left: 8px;
      }
    }
    // Add size adjustments here
    min-width: 150px;
    height: 48px;
    padding: 4px 0px;
    font-size: 14px;
    justify-content: center;
    align-items: center;
    flex: 1 0 auto;
    @media (max-width: 600px) {
      width: 100%; /* Full width for small screens */
    }

    @media (min-width: 601px) and (max-width: 900px) {
      flex: 1 0 45%; /* Adjust width for medium screens */
    }

    @media (min-width: 901px) {
      flex: 1;
    }
  }
`
export const StyledButton = styled(Button)`
  && {
    background-color: ${(props) => props.theme.tokens.primary};
    border-radius: 6px;
    // Add size adjustments here
    min-width: 150px;
    height: 48px;
    margin: 20px auto;
    padding: 4px 0px;
    font-size: 14px;
    justify-content: center;
    align-items: center;
    flex: 1 0 0;

    & > .MuiSvgIcon-root {
      color: ${(props) => props.theme.tokens.onPrimaryFixed};
      margin-right: 8px;
    }
  }
`

export const StyledLabel = styled(Typography)(({ theme }) => ({
  color: theme.tokens.secondary,
  alignItems: 'center',
  gap: '8px',
  display: 'flex',
  [theme.breakpoints.up('md')]: {
    gap: '3px',
  },
  [theme.breakpoints.up('lg')]: {
    gap: '5px',
  },
  [theme.breakpoints.up('xl')]: {
    gap: '8px',
  },
}))
export const HeaderBox = styled(Box)`
  && {
    display: flex;
    align-items: flex-start;
    width: 100%;
    border-bottom: 2px solid ${({ theme }) => theme.tokens.secondary};
    padding-bottom: 26px;
  }
`
export const StyledBox = styled(Box)`
  && {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
  }
`

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
`

export const ListOfEnergySourceBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'flex-start',
  margin: '20px 20px',
  padding: '10px 0px',
  gap: '35px',
  [theme.breakpoints.up('md')]: {
    gap: '3px',
  },
  [theme.breakpoints.up('lg')]: {
    gap: '8px',
  },
  [theme.breakpoints.up('xl')]: {
    gap: '35px',
  },
}))

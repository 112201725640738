import { useEffect, useState } from 'react'
import React from 'react'
import DetailsWrapper from '../../../layouts/DetailsWrapper'
import { TextField, MenuItem, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import Feedback from '../../../components/Feedback'
import LogoLoader from '../../../components/LogoLoader'
import PermMediaIcon from '@mui/icons-material/PermMedia'
import UserDetails from './UserDetails'
import EditIcon from '@mui/icons-material/Edit'
import api from '../../../Middleware/api/api'
import { useAuth } from '../../../services/contexts'
import ErrorFeedback from '../../../components/DataTable/Feedback'
import {
  PaperItem,
  PaperItemBody,
  StyledTypographyHeading,
  CustomizedButton,
} from './styles'
import RouteNotFound from '../../RouteNotFound'
import UserFormPopup from './UserFormPopup'

/**
 * The main component that shows the user details after user clicks on the details in the table of Nutzerverwaltung page.
 * This also holds the button to edit the details of a contract.
 *
 * @returns {React.ReactNode} - Returns all the component that are show in user details page.
 */

export default function NutzerDetail() {
  const { token } = useAuth()
  const { t } = useTranslation()
  const [loading, setLoading] = useState(false)
  const [selectedContractId, setSelectedContractId] = useState(null)
  const [isFormPopupOpen, setIsFormPopupOpen] = useState(false)
  const [completed, setCompleted] = useState(false)
  const [error, setError] = useState(false)
  const [showEditButton, setShowEditButton] = useState(false)
  const [editContractData, setEditContractData] = useState()
  const [isConsumer, setIsConsumer] = useState(false)
  const [refreshed, setRefreshed] = useState(false)
  const [updatedContract, setUpdatedContract] = useState()

  ////////////
  const location = useLocation()
  const { user, contractDetails } = location.state || {}
  const [contractDataDto, setContractDataDto] = useState(
    user?.contractDataDto || []
  )

  useEffect(() => {
    if (contractDetails) {
      updatedContract && refreshed
        ? setEditContractData(updatedContract)
        : setEditContractData(contractDetails)
      setShowEditButton(true)

      // this portion of code is used to show the selected contract in the dropdown menu
      // If user has come over user details page by clicking on the Details in the table
      const contractIndex = user.contractDataDto.findIndex(
        (contract) => contract.contractKey === contractDetails.contractKey
      )
      if (contractIndex !== -1) {
        setSelectedContractId(contractDataDto[contractIndex].contractKey)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contractDetails, contractDataDto, updatedContract])

  const handleContractChange = (event) => {
    setShowEditButton(true)
    const selectedContractId = event.target.value
    const selectedContract = contractDataDto.find(
      (contract) => contract.contractKey === selectedContractId
    )
    if (selectedContract) {
      setEditContractData(
        refreshed &&
          updatedContract?.contractKey === selectedContract.contractKey
          ? updatedContract
          : selectedContract
      )
    }

    setSelectedContractId(selectedContractId)
    setRefreshed(false)
  }
  /**
   * This api call is use to fetch the single contract data.
   * For detail response visit the following page in docs.
   * [API Respnse Documentation](./src/docs/apiResponse.md)
   *
   */
  const fetchContractDetails = async () => {
    try {
      const response = await api.fetchContractData(
        token,
        editContractData?.contractKey
      )
      if (response.data) {
        const updatedData = response?.data?.data
        if (updatedData) {
          setUpdatedContract(updatedData)
          setEditContractData(updatedData)

          setContractDataDto((prevContracts) =>
            prevContracts.map((contract) =>
              contract.contractKey === updatedData.contractKey
                ? updatedData
                : contract
            )
          )
        }
      }
    } catch (error) {
      setError(true)
    }
  }

  const handleClick = (value) => {
    if (value?.contractType === 'CONSUMER') setIsConsumer(true)

    setEditContractData(refreshed ? updatedContract : value)
    setIsFormPopupOpen(true)
  }

  const closeFeedback = () => {
    setCompleted(false)
    // setPayload(null)
    setError(false)
  }
  if (!user) {
    return (
      <RouteNotFound
        title="Seite nicht gefunden"
        description="Bitte überprüfen Sie den Link oder gehen Sie zur Benutzerverwaltung"
        buttonText="zum Benutzerverwaltung"
        to="/nutzer"
      />
    )
  }
  return (
    <>
      <DetailsWrapper
        title={t('LBLUserDetails')}
        loading={loading}
        helmet="invoices"
        backButtonConfig={{ label: t('lblBacktoview'), url: '/nutzer' }}
        contract={''}
        fullName={user.fullName}
      >
        <PaperItem>
          <StyledTypographyHeading>{t('LBLContracts')}</StyledTypographyHeading>

          <div
            style={{
              width: '20%',
              height: '70px',
              textAlign: 'left',
              float: 'left',
            }}
          >
            <div
              style={{
                backgroundColor: 'transparent',
                marginLeft: '25px',
                marginTop: '-15px',
              }}
            >
              <TextField
                select
                id="contractDetails"
                label={t('LBLContractSelection')}
                onChange={handleContractChange}
                value={selectedContractId || ''}
                name="contractDetailSelection"
                style={{
                  width: '300px',
                  color: (theme) => theme.props.surfaceBright,
                }}
                SelectProps={{
                  MenuProps: {
                    PaperProps: {
                      style: {
                        width: '200px',
                        color: (theme) => theme.props.surfaceBright,
                      },
                    },
                  },
                }}
              >
                {contractDataDto && contractDataDto.length > 0 ? (
                  contractDataDto.map((contract, index) => (
                    <MenuItem
                      size="large"
                      key={index}
                      value={contract.contractKey}
                    >
                      {contract?.contractType === 'CONSUMER'
                        ? ` ${t('LBLConsumerContract')} ${index + 1}`
                        : `${t('LBLProducerContract')} ${index + 1}`}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem value="">
                    <Typography fontWeight="bold" variant="h4" gutterBottom>
                      {t('LBLSelectContract')}
                    </Typography>
                  </MenuItem>
                )}
              </TextField>
            </div>
          </div>
          {showEditButton && (
            <CustomizedButton
              id="Edit-button"
              onClick={() => handleClick(editContractData)}
              variant="contained"
            >
              <EditIcon />
              {t('LBLEditUserDetail')}
            </CustomizedButton>
          )}
        </PaperItem>

        {selectedContractId && selectedContractId != null ? (
          !contractDataDto ? (
            <LogoLoader loading={true} />
          ) : (
            contractDataDto
              .map((contract) =>
                updatedContract &&
                contract.contractKey === updatedContract.contractKey
                  ? updatedContract
                  : contract
              )
              .map((contract) => {
                if (contract.contractKey === selectedContractId) {
                  return (
                    <>
                      <UserDetails
                        contract={refreshed ? updatedContract : contract}
                      />
                    </>
                  )
                } else {
                  return null
                }
              })
          )
        ) : contractDetails ? (
          <>
            <UserDetails
              contract={refreshed ? updatedContract : contractDetails}
            />
          </>
        ) : (
          <>
            <PaperItemBody>
              <PermMediaIcon
                sx={{
                  fontSize: 140,
                  padding: '20px',
                  color: (theme) => theme.tokens.secondary,
                }}
              />
              <Typography fontWeight="bold" variant="h2" gutterBottom>
                {t('LBLSelectContract')}
              </Typography>
            </PaperItemBody>
          </>
        )}
      </DetailsWrapper>

      <UserFormPopup
        onLoadingChanged={setLoading}
        isConsumer={isConsumer}
        onFormCompleted={setCompleted}
        onIsError={setError}
        contract={editContractData}
        onContractUpdated={setUpdatedContract}
        onUpdateUserContracts={setContractDataDto}
        onRefreshed={setRefreshed}
        isEditingContract={isFormPopupOpen}
        refetch={fetchContractDetails}
        onIsFormOpened={setIsFormPopupOpen}
      />

      <Feedback
        open={completed}
        title={t('LBLContractUpdated')}
        message={
          <>
            <Typography align="center">
              {t('LBLUserContractUpdated')}
            </Typography>
          </>
        }
        handleClose={closeFeedback}
      />
      <ErrorFeedback
        open={error}
        title={t('LBLErrorTitle')}
        severity="warning"
        message={
          <Typography variant="subtitle1" sx={{ width: 400, mx: 10 }}>
            {t('LBLContractUpdateEror')}
          </Typography>
        }
        handleClose={closeFeedback}
      />
    </>
  )
}

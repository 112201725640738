import * as React from 'react'
import { Button, Grid, MenuItem, IconButton } from '@mui/material'
import { useTranslation } from 'react-i18next'
import SearchIcon from '@mui/icons-material/Search'
import { useTheme } from '@mui/material/styles'
import {
  StyledGridContainer,
  StyledLabel,
  StyledSelect,
  StyledSelectContainer,
  StyledTextField,
  StyledText,
} from './components'

export function UserFilterHeader({
  searchText,
  onSearchTextChanged,
  contractTypeFilter,
  onContractTypeFilterChanged,
  contractStatusFilter,
  onContractStatusFilterChanged,
  onResetFiltersPressed,
}) {
  const { t } = useTranslation()
  const theme = useTheme()

  const handleContractTypeFilterChange = (event) => {
    onContractTypeFilterChanged(event.target.value)
  }

  const handleContractStatusFilterChange = (event) => {
    onContractStatusFilterChanged(event.target.value)
  }

  return (
    <StyledGridContainer container rowSpacing={1}>
      <Grid
        item
        xs={12}
        md={6}
        style={{ display: 'flex', alignItems: 'flex-end' }}
      >
        <StyledTextField
          placeholder={t('LBLSearch')}
          value={searchText}
          onChange={(e) => onSearchTextChanged(e.target.value)}
          variant="standard"
          margin="normal"
          InputProps={{
            style: { width: '100%' },
            disableUnderline: true,
            endAdornment: (
              <IconButton size="large" edge="start">
                <SearchIcon sx={{ color: theme.tokens.primary }} />
              </IconButton>
            ),
          }}
        />
      </Grid>
      <Grid item xs={12} md={2}>
        {/* Contract type filter */}
        <StyledSelectContainer>
          <StyledLabel variant="h6">{t('LBLContractType')}</StyledLabel>
          <StyledSelect
            value={contractTypeFilter}
            onChange={handleContractTypeFilterChange}
            displayEmpty
            variant="outlined"
          >
            <MenuItem value="">{t('LBLAll')}</MenuItem>
            <MenuItem value="PRODUCER">{t('LBLProducer')}</MenuItem>
            <MenuItem value="CONSUMER">{t('LBLConsumer')}</MenuItem>
          </StyledSelect>
        </StyledSelectContainer>
      </Grid>
      <Grid item xs={12} md={2}>
        {/* Contract status filter */}
        <StyledSelectContainer>
          <StyledLabel variant="h6">{t('LBLcontractStatus')}</StyledLabel>
          <StyledSelect
            value={contractStatusFilter}
            onChange={handleContractStatusFilterChange}
            displayEmpty
            variant="outlined"
          >
            <MenuItem value="">{t('LBLAll')}</MenuItem>
            <MenuItem value="ACTIVE">{t('LBLActive')}</MenuItem>
            <MenuItem value="INACTIVE">{t('LBLInactive')}</MenuItem>
            <MenuItem value="IN_PROGRESS">{t('LBLInreview')}</MenuItem>
          </StyledSelect>
        </StyledSelectContainer>
      </Grid>
      <Grid
        item
        xs={12}
        md={2}
        style={{
          alignItems: 'end',
          justifyContent: 'flex-end',
          display: 'flex',
        }}
      >
        <Button onClick={onResetFiltersPressed}>
          <StyledText variant="h5" align="left">
            {t('LBLResetFilters')}
          </StyledText>
        </Button>
      </Grid>
    </StyledGridContainer>
  )
}

import styled from 'styled-components/macro'
import {
  Button,
  Paper as MuiPaper,
  Box,
  Typography,
  TextField as MuiTextField,
} from '@mui/material'

/**
 * This is the style file to hold the styles used in Energy Mix page.
 */
export const HeaderTypography = styled(Typography)`
  && {
    color: ${(props) => props.theme.tokens.onSurfaceVariant};
  }
`
export const OuterSurface = styled(MuiPaper)`
  && {
    background-color: ${(props) => props.theme.tokens.surfaceBright};
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    padding: 50px 50px;
    max-width: 98%;
    display: flex;
    flex-direction: column;
    align-items: start;
  }
`

//styling for buttons
export const ButtonGroup = styled(Box)`
&&{
  display: flex; 
  padding: 24px 0px; 
  position: relative; 
  top: 0; 
  left: 0;
  button {
    border-radius: 0;
  }
  button:first-of-type {
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
  }
  button:last-of-type {
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
  }
  @media (max-width: 600px) {
    flex-direction: column;
    align-items: center;
    align-self: center;
    button {
      width: 200px;
    }
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    button:first-of-type {
      border-bottom-left-radius: 0px;
      border-top-right-radius: 20px;
      border-bottom: none;
    }
    button:last-of-type {
      border-top-right-radius: 0px;
      border-bottom-left-radius: 20px;
      border-top: none;
    }

  @media (min-width: 601px) and (max-width: 900px) {
    flex-direction: row;
    button {
      flex: 1%; /* Adjust width of buttons for medium screens */
    }

}
`

export const StyledMenuButton = styled(Button)`
  && {
    &.MuiButton-containedPrimary {
      background-color: ${(props) => props.theme.tokens.tertiaryFixed};
      border: 1px solid ${(props) => props.theme.tokens.primary};
    }
    &.MuiButton-outlined {
      border: 1px solid ${(props) => props.theme.tokens.primary};
    }
    &.selected {
      background-color: ${(props) => props.theme.tokens.tertiaryFixed};
      color: ${(props) => props.theme.tokens.onTertiaryFixedVariant};
      svg {
        margin-left: 8px;
      }
    }
    // Add size adjustments here
    min-width: 150px;
    height: 48px;
    padding: 4px 0px;
    font-size: 14px;
    justify-content: center;
    align-items: center;
    flex: 1 0 auto;
    @media (max-width: 600px) {
      width: 100%; /* Full width for small screens */
    }

    @media (min-width: 601px) and (max-width: 900px) {
      flex: 1 0 45%; /* Adjust width for medium screens */
    }

    @media (min-width: 901px) {
      flex: 1;
    }
  }
`
export const StyledButton = styled(Button)`
  && {
    background-color: ${(props) => props.theme.tokens.primary};
    border-radius: 6px;
    // Add size adjustments here
    min-width: 150px;
    height: 48px;
    margin: 20px auto;
    padding: 4px 0px;
    font-size: 14px;
    justify-content: center;
    align-items: center;
    flex: 1 0 0;

    & > .MuiSvgIcon-root {
      color: ${(props) => props.theme.tokens.onPrimaryFixed};
      margin-right: 8px;
    }
  }
`

export const StyledDiv = styled(Box)(({ theme }) => ({
  backgroundColor: theme.tokens.tertiary,
  display: 'flex',
  flexWrap: 'wrap',
  padding: '16px 194px 28px 24px',
  alignItems: 'flex-end',
  gap: '53px',
  [theme.breakpoints.up('xs')]: {
    width: '98%', // Adjust top value for extra-small screens
  },
  [theme.breakpoints.up('sm')]: {
    width: '98%', // Adjust top value for small screens
  },
  [theme.breakpoints.up('md')]: {
    width: '98%', // Adjust top value for medium screens
  },
  [theme.breakpoints.up('lg')]: {
    width: '98%', // Adjust top value for large screens
  },
  [theme.breakpoints.up('xl')]: {
    width: '98%', // Adjust top value for extra-large screens
  },
}))
export const StyledLabel = styled(Typography)`
  && {
    color: ${(props) => props.theme.tokens.secondary};
    font-weight: bold;
  }
`
export const StyledLabelNonBold = styled(Typography)`
  && {
    color: ${(props) => props.theme.tokens.secondary};
  }
`
export const StyledText = styled(Typography)`
  && {
    color: ${(props) => props.theme.tokens.onPrimaryFixed};
  }
`

export const StyledButtonText = styled(Typography)`
  && {
    color: ${(props) => props.theme.tokens.onTertiaryFixedVariant};
  }
`
export const StyledTextField = styled(MuiTextField)`
  && {
    border: 1px solid ${(props) => props.theme.tokens.outline};
    background-color: ${(props) => props.theme.tokens.surfaceBright};
    height: 40px;
    align-items: center;
    display: relative;
    top: 15px;
    input::placeholder {
      color: ${(props) => props.theme.tokens.secondary};
      font-weight: bold;
    }
    input {
      color: ${(props) => props.theme.tokens.secondary};
    }
  }
`

export const StyledTimePicker = styled(MuiTextField)`
  && {
    border: 1px solid ${(props) => props.theme.tokens.outline};
    background-color: ${(props) => props.theme.tokens.surfaceBright};
    height: 40px;
    width: 96px;
    align-items: center;
    display: relative;
    top: 15px;
    input::placeholder {
      color: ${(props) => props.theme.tokens.secondary};
      font-weight: bold;
    }
    input {
      color: ${(props) => props.theme.tokens.secondary};
    }
  }
`

export const ChartBox = styled(Box)`
  && {
    display: flex;
    flex-direction: row;
    margin-top: 60px;
    margin-left: 40px;
    width: 100%;
    @media (max-width: 1280px) {
      flex-direction: row;
      display: flex;
      margin-top: 40px;
      margin-left: 30px;
      width: 90%;
    }
    @media (max-width: 768px) {
      flex-direction: row;
      display: flex;
      margin-top: 30px;
      margin-left: 20px;
      width: 80%;
    }
    @media (max-width: 468px) {
      flex-direction: column;
      align-items: center;
      width: 40%;
    }
  }
`

export const ChartDiv = styled.div`
  height: auto;
  width: 90%;
  text-align: center;
  @media (max-width: 1280px) {
    text-align: center;
    height: auto;
    width: 70%;
  }
  @media (max-width: 768px) {
    text-align: center;
    height: auto;
    width: 40%;
  }
  @media (max-width: 468px) {
    text-align: center;
    height: auto;
    width: 25%;
  }
`

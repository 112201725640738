import { CardContent, Typography } from '@mui/material'
import { View, Spacer, Item } from './styles'
import { useTranslation } from 'react-i18next'
import { useCallback } from 'react'
import { styled } from '@mui/material/styles'
import React from 'react'
import { formatDate } from './formatDate'

const StyledView = styled(View)`
  && {
    border-radius: 28px;
    overflow: hidden;
    background-color: ${(props) => props.theme.tokens.surfaceBright};
  }
`

const StyledTypography = styled(Typography)`
  && {
    color: ${(props) => props.theme.tokens.secondary};
  }
`

function UserInformation({ contract }) {
  const { t } = useTranslation()

  const firma = {
    contractNumber: contract?.contractNumber,
    Name:
      contract?.fullName &&
      `${t(contract?.salutation) + ' ' + contract?.fullName}`,
    Adresse: contract?.address?.street
      ? `${contract?.address?.street} ${contract?.address?.houseNr} ${contract?.address?.postalCode} ${contract?.address?.city} `
      : null,
    BillingOwner: contract?.billingAddress?.addressOwner,
    BillingAddress: contract?.billingAddress?.street
      ? `${contract?.billingAddress?.street} ${contract?.billingAddress?.houseNr} ${contract?.billingAddress?.postalCode} ${contract?.billingAddress?.city} `
      : null,
    Email: contract?.emailAddress,
    PhoneNumber: contract?.phoneNumber,
    registeredCourt: contract?.registeredCourt,
    registrationNumber: contract?.registrationNumber,
  }
  const user = {
    contractNumber: contract?.contractNumber,
    Name:
      contract?.fullName &&
      `${t(contract?.salutation) + ' ' + contract?.fullName}`,
    Adresse: contract?.address?.street
      ? `${contract?.address?.street} ${contract?.address?.houseNr} ${contract?.address?.postalCode} ${contract?.address?.city} `
      : null,
    BillingOwner: contract?.billingAddress?.addressOwner,
    BillingAddress: contract?.billingAddress?.street
      ? `${contract?.billingAddress?.street} ${contract?.billingAddress?.houseNr} ${contract?.billingAddress?.postalCode} ${contract?.billingAddress?.city} `
      : null,
    Email: contract?.emailAddress,
    PhoneNumber: contract?.phoneNumber,
    BirthDate: formatDate(contract?.dateOfBirth),
  }
  const nutzer = contract?.salutation === 'Firma' ? firma : user
  const renderKey = useCallback(
    (key) => {
      switch (key) {
        case 'EMail':
          return 'E-Mail'
        case 'contractNumber':
          return t('LBLCustomerNum')
        case 'Adresse':
          return t('LBLAddress')
        case 'BillingOwner':
          return t('LBLBillingAddressOwner')
        case 'BillingAddress':
          return t('LBLBillingAddress')
        case 'PhoneNumber':
          return t('LBLHandy')
        case 'BirthDate':
          return t('LBLBirthDate')
        case 'registeredCourt':
          return t('LBLRegisterCourt')
        case 'registrationNumber':
          return t('LBLRegisterNumber')
        default:
          return key
      }
    },
    [t]
  )
  return (
    <>
      <StyledView>
        <CardContent>
          <StyledTypography fontWeight="bold" variant="h4" gutterBottom>
            {t('LBLUserInfo')}
          </StyledTypography>
          <Spacer mb={4} />
          <StyledTypography variant="body2" component="div" gutterBottom>
            {nutzer && (
              <>
                {Object.entries(nutzer).map(([key, value]) => {
                  return (
                    <>
                      <Item key={key}>
                        <StyledTypography fontWeight="fontWeightMedium">
                          {renderKey(key)}
                        </StyledTypography>
                        {key === 'Adresse' || key === 'BillingAddress' ? (
                          key === 'Adresse' ? (
                            <StyledTypography
                              fontWeight="fontWeightRegular"
                              align="right"
                            >
                              {contract?.address?.street}{' '}
                              {contract?.address?.houseNr} <br />
                              {contract?.address?.postalCode}{' '}
                              {contract?.address?.city}
                            </StyledTypography>
                          ) : (
                            <StyledTypography
                              fontWeight="fontWeightRegular"
                              align="right"
                            >
                              {contract?.billingAddress?.street}{' '}
                              {contract?.billingAddress?.houseNr} <br />
                              {contract?.billingAddress?.postalCode}{' '}
                              {contract?.billingAddress?.city}
                            </StyledTypography>
                          )
                        ) : (
                          <StyledTypography
                            fontWeight="fontWeightRegular"
                            align="right"
                          >
                            {['string', 'number'].includes(typeof value)
                              ? value
                              : value?.join(', ') || null}
                          </StyledTypography>
                        )}
                      </Item>
                      <div style={{ height: '5px' }}></div>
                    </>
                  )
                })}
              </>
            )}
          </StyledTypography>
        </CardContent>
      </StyledView>
    </>
  )
}

export default UserInformation

import { useEffect, useState } from 'react'
import React from 'react'
import styled, { withTheme } from 'styled-components/macro'
import { useAuth } from '../../services/contexts/AuthContext'
import api from '../../Middleware/api/api'
import { useTranslation } from 'react-i18next'
import { Card as MuiCard, Box } from '@mui/material'
import { spacing } from '@mui/system'
import LogoLoader from '../../components/LogoLoader'
import { formatDecimal } from '../../services/utils/format-numbers'
import {
  TotalUserIcon,
  TotalConsumerIcon,
  TotalProducerIcon,
  TotalCapacityIcon,
} from '../../components/icons/myIcons'
import {
  BoxTypography,
  BoxData,
  IconWrapper,
  StyledBox,
  Container,
} from './styles'
import EnergyKpi from './EnergyKpis'

const Card = styled(MuiCard)(({ theme }) => ({
  ...spacing,
  backgroundColor: theme.tokens.transparentBackground,
  marginRight: 18,
}))
/**
 * This is the component to show main four KPIs on dashboard.
 * It holds all the KPIs and grpah.
 *
 * @returns {React.ReactNode} A React component that renders the KPIs and also the Bar graph.
 */
function BarChart() {
  const { t } = useTranslation()
  const [userData, setUserData] = useState()
  const { token } = useAuth()

  useEffect(() => {
    api.dashboardData(token).then((response) => {
      setUserData(response?.data?.data)
    })
  }, [token])

  return (
    <React.Fragment>
      {/* Here the Card and Container components are designed to show the four main KPIs on the dashboard. */}
      <Card>
        {!userData ? (
          <LogoLoader loading={true}></LogoLoader>
        ) : (
          <Container>
            <StyledBox>
              <Box>
                <BoxTypography variant="h5">{t('LBLTotalUsers')}</BoxTypography>
                <BoxData variant="h1">
                  {userData !== null && userData?.totalUsers > 0
                    ? userData?.totalUsers
                    : '0'}{' '}
                </BoxData>
              </Box>
              <IconWrapper>
                <TotalUserIcon style={{ fontSize: 44 }} />
              </IconWrapper>
            </StyledBox>

            <StyledBox>
              <Box>
                <BoxTypography variant="h5">
                  {t('LBLTotalConsumers')}
                </BoxTypography>
                <BoxData variant="h1">
                  {userData !== null && userData?.totalConsumers > 0
                    ? userData?.totalConsumers
                    : '0'}{' '}
                </BoxData>
              </Box>
              <TotalConsumerIcon style={{ fontSize: 44 }} viewBox="0 0 49 49" />
            </StyledBox>

            <StyledBox>
              <Box>
                <BoxTypography variant="h5">
                  {t('LBLTotalProducers')}
                </BoxTypography>
                <BoxData variant="h1">
                  {userData !== null && userData?.totalProducers > 0
                    ? userData?.totalProducers
                    : '0'}{' '}
                </BoxData>
              </Box>
              <TotalProducerIcon style={{ fontSize: 44 }} viewBox="0 0 48 49" />
            </StyledBox>

            <StyledBox>
              <Box>
                <BoxTypography variant="h5">
                  {t('LBLTotalEnergyCapacity')}
                </BoxTypography>
                <BoxData variant="h1">
                  {userData !== null && userData?.totalEnergyCapacity > 0
                    ? formatDecimal(userData?.totalEnergyCapacity) + ' kW'
                    : '0,00 kW'}{' '}
                </BoxData>
              </Box>
              <TotalCapacityIcon style={{ fontSize: 44 }} viewBox="0 0 48 49" />
            </StyledBox>
          </Container>
        )}
      </Card>
      {/*Here the EnergyKpi is another component that is use to hold the data KPIs and graph on the front page of dashboard */}
      <div style={{ height: '10px' }} />
      <EnergyKpi />
    </React.Fragment>
  )
}
export default withTheme(BarChart)
